/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useReducer, useRef } from 'react'
import { Helmet } from 'react-helmet'
import InputMask from 'react-input-mask'
import Layout from '../components/Layout/index'
import BuscarLoja from '../components/BuscarLoja/buscarloja.js'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Collapse, CardBody, Card } from 'reactstrap'
import Axios from 'axios'
import { scroller, Link as ScrollerLink } from 'react-scroll'
import jsonCidades from '../utils/cidades.json'
import RenderForm from 'gatsby-plugin-cloudofertas'

// CSS e SCSS
import './styles/contato.scss'
// ICONES
import ScrollTop from '../images/contato/ScrollTop.svg'
import estrela from '../images/contato/estrela.svg'
import QueroSerFornecedor from '../images/contato/produto.svg'
import LojasTelefone from '../images/contato/lojas.svg'
import Ofertas from '../images/contato/oferta.svg'
import ProcessoSeletivo from '../images/contato/ProcessoSeletivo.svg'
// import FranquiaImoveis from '../images/contato/FranquiaImoveis.svg'
// import Delivery from '../images/contato/delivery.svg'
import Pagamento from '../images/contato/Pagamento.svg'
import TrocasProdutos from '../images/contato/trocas.svg'
import ClubDia from '../images/contato/ClubDia.svg'
import RedArrow from '../images/contato/RedArrow.svg'
import DPO from '../images/contato/dpo.svg'

const CentralAjuda = ({ setCollapse, idScroller, handleCollapse }) => {
  const BotaoScroll = ({ nameCollapse, icon, title }) => {
    return (
      <div className='col-lg-3 col-12'>
        <ScrollerLink to={idScroller} spy={true}
          smooth={true}
          offset={-20}
          duration={40}>
          <button className="mt-3 pl-3 d-flex align-items-center CentralAjudaStyle" onClick={() => handleCollapse(nameCollapse)}>
            <img style={{ width: 27, height: 27 }} src={icon} alt="icone selecionado" />
            <span className='ml-3 text-blue font-Lato font-size-1'>{title}</span>
          </button>
        </ScrollerLink>
      </div>
    )
  }

  return (
    <div className='container font-size-1'>
      <h1 tabIndex={0} className='text-red mt-lg-4 mt-4'>Central de Ajuda</h1>
      <p>Escolha a categoria sobre a qual tem dúvidas</p>
      <div className='row'>
        <BotaoScroll nameCollapse='Ofertas' icon={Ofertas} title="Ofertas" />
        <BotaoScroll nameCollapse='LojasTelefone' icon={LojasTelefone} title="Lojas e Telefones" />
        <BotaoScroll nameCollapse='ProcessoSeletivo' icon={ProcessoSeletivo} title="Processo Seletivo" />
        {/* <BotaoScroll nameCollapse='FranquiaImoveis' icon={FranquiaImoveis} title="Franquia e Imóveis" /> */}
        <BotaoScroll nameCollapse='Pagamento' icon={Pagamento} title="Pagamento" />
        <BotaoScroll nameCollapse='TrocasProdutos' icon={TrocasProdutos} title="Trocas de Produtos" />
        {/* <BotaoScroll nameCollapse='QueroSerFornecedor' icon={QueroSerFornecedor} title="Quero Ser um Fornecedor" /> */}
        {/* <BotaoScroll nameCollapse='Delivery' icon={Delivery} title="Delivery" /> */}
        <div className='col-lg-3 col-12'>
          <a href='https://diasupermercados-privacy.my.onetrust.com/webform/e8ee1561-5f69-4f41-ab56-108e1791052c/8e8e6d7f-edff-42ab-9d22-631e5b3e4b77' target="_blank" className="mt-3 p-3  d-flex align-items-center CentralAjudaStyle" rel="noreferrer">
            <img style={{ width: 27, height: 27 }} src={DPO} alt="icone selecionado" />
            <span className='ml-3 text-blue font-Lato'>Privacidade de Dados (DPO)</span>
          </a>
        </div>
        <BotaoScroll nameCollapse='ClubDia' icon={ClubDia} title="ClubDia" />
        <BotaoScroll nameCollapse='AchadosPerdidos' icon={estrela} title="Achados e Perdidos" />
      </div>
    </div>
  )
}

const SectionDuvidas = ({ idScroller, setCollapse, collapse, handleCollapse }) => {
  return (
    <>
      <CentralAjuda setCollapse={setCollapse} idScroller={idScroller} handleCollapse={handleCollapse} />
      <div className='container pb-5 d-none d-sm-block' id='PerguntasFrequentes'>
        <h1 tabIndex={0} className='text-red mt-lg-5 mb-lg-4'>Perguntas Frequentes</h1>
        <div className='row'>
          <div className='col-4'>
            <div className='row'>
              <div className='col-12'>
                <button id={`${collapse === 'Ofertas' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => handleCollapse('Ofertas')} className=" d-flex faqStyle font-size-1">
                  <img style={{ width: 27, height: 27 }} className="ml-4" src={Ofertas} alt="icone selecionado" />
                  <span className='ml-3 text-blue font-Lato'>Ofertas</span>
                  <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                </button>
              </div>
              <div className='col-12'>
                <button id={`${collapse === 'LojasTelefone' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => handleCollapse('LojasTelefone')} className=" d-flex faqStyle font-size-1">
                  <img style={{ width: 27, height: 27 }} className="ml-4" src={LojasTelefone} alt="icone selecionado" />
                  <span className='ml-3 text-blue font-Lato'>Lojas e Telefone</span>
                  <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                </button>
              </div>
              <div className='col-12'>
                <button id={`${collapse === 'ProcessoSeletivo' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => handleCollapse('ProcessoSeletivo')} className=" d-flex faqStyle font-size-1">
                  <img style={{ width: 27, height: 27 }} className="ml-4" src={ProcessoSeletivo} alt="icone selecionado" />
                  <span className='ml-3 text-blue font-Lato'>Processo Seletivo</span>
                  <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                </button>
              </div>
              {/* <div className='col-12'>
                <button id={`${collapse === 'FranquiaImoveis' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => handleCollapse('FranquiaImoveis')} className=" d-flex faqStyle font-size-1">
                  <img style={{ width: 27, height: 27 }} className="ml-4" src={FranquiaImoveis} alt="icone selecionado" />
                  <span className='ml-3 text-blue font-Lato'>Franquia e Imóveis</span>
                  <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                </button>
              </div> */}
              <div className='col-12'>
                <button id={`${collapse === 'Pagamento' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => handleCollapse('Pagamento')} className=" d-flex faqStyle font-size-1">
                  <img style={{ width: 27, height: 27 }} className="ml-4" src={Pagamento} alt="icone selecionado" />
                  <span className='ml-3 text-blue font-Lato'>Pagamento</span>
                  <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                </button>
              </div>
              <div className='col-12'>
                <button id={`${collapse === 'TrocasProdutos' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => handleCollapse('TrocasProdutos')} className=" d-flex faqStyle font-size-1">
                  <img style={{ width: 27, height: 27 }} className="ml-4" src={TrocasProdutos} alt="icone selecionado" />
                  <span className='ml-3 text-blue font-Lato'>Trocas de Produtos</span>
                  <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                </button>
              </div>
              {/* <div className='col-12'>
                <button id={`${collapse === 'QueroSerFornecedor' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => handleCollapse('QueroSerFornecedor')} className=" d-flex faqStyle font-size-1">
                  <img style={{ width: 27, height: 27 }} className="ml-4" src={QueroSerFornecedor} alt="icone selecionado" />
                  <span className='ml-3 text-blue font-Lato text-left'>Quero Ser um Fornecedor</span>
                  <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                </button>
              </div> */}
              {/* <div className='col-12'>
                <button id={`${collapse === 'Delivery' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => handleCollapse('Delivery')} className=" d-flex faqStyle font-size-1">
                  <img style={{ width: 27, height: 27 }} className="ml-4" src={Delivery} alt="icone selecionado" />
                  <span className='ml-3 text-blue font-Lato'>Delivery</span>
                  <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                </button>
              </div> */}
              <div className='col-12'>
                <button id={`${collapse === 'ClubDia' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => handleCollapse('ClubDia')} className=" d-flex faqStyle font-size-1">
                  <img style={{ width: 27, height: 27 }} className="ml-4" src={ClubDia} alt="icone selecionado" />
                  <span className='ml-3 text-blue font-Lato'>ClubDia</span>
                  <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                </button>
              </div>
              <div className='col-12'>
                <button id={`${collapse === 'AchadosPerdidos' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => handleCollapse('AchadosPerdidos')} className=" d-flex faqStyleLast font-size-1">
                  <img style={{ width: 27, height: 27 }} className="ml-4" src={estrela} alt="icone selecionado" />
                  <span className='ml-3 text-blue font-Lato'>Achados e Perdidos</span>
                  <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                </button>
              </div>
            </div>
          </div>
          <div className='col-8'>
            <div className='row'>
              <div className='col-12' id=''>
                <Collapse id='card-contato' isOpen={collapse === 'AchadosPerdidos'}>
                  <Card className="border-0">
                    <CardBody id="collapseText">
                      <h4 className='font-Lato font-weight-bold text-blue'>Esqueci um <span className='blueHighlight'>item pessoal</span> na loja.<br></br> E agora?</h4>
                      <p className='font-size-1'>Não se preocupe. Se você esqueceu algo, venha até a loja e veja com a nossa equipe se o seu pertence foi encontrado e deixado com algum dos nossos colaboradores.</p>
                      <hr />
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
              <div className='col-12'>
                <Collapse id='card-contato' isOpen={collapse === 'Ofertas'}>
                  <Card className="border-0">
                    <CardBody id="collapseText">
                      <h4 className='font-Lato font-weight-bold text-blue'>Meu <span className='blueHighlight'>produto</span> predileto está em promoção.<br></br> Será que está disponível na loja mais próxima?</h4>
                      <p className='font-size-1'>Poxa, não temos acesso ao estoque individuais das lojas para te responder essa. Mas você pode ir até a loja mais próxima para ver! <a className='link-red' target='_blank' rel="noreferrer" href='https://www.dia.com.br/lojas/'>Clique aqui</a> e consulte qual é. </p>
                      <hr />
                      <h4 className='font-Lato font-weight-bold text-blue'>Quero saber o preço de um <span className='blueHighlight'>produto</span>.<br></br> Como eu descubro?</h4>
                      <p className='font-size-1'>Para saber o preço de algum produto é só ir até uma <a className='link-red' target='_blank' rel="noreferrer" href='https://www.dia.com.br/lojas/'> loja DIA</a> mais próxima! Aproveite também as nossas promoções.</p>
                      <hr />
                      <h4 className='font-Lato font-weight-bold text-blue'>Como faço para saber quais são os <span className='blueHighlight'>produtos</span> em <span className='blueHighlight'>oferta</span>?</h4>
                      <p className='font-size-1'>Tem dois jeitos bem simples de saber isso: Você pode nos mandar uma mensagem no Whatsapp <a href='https://api.whatsapp.com/send?phone=5511953118685' className='link-red'>11-95311-8685</a>  ou, se preferir,  consultar aqui mesmo, no nosso site na aba “<a target='_blank' rel="noreferrer" className='link-red' href='https://www.dia.com.br/lojas/'>Ofertas das semanas</a>”.</p>
                      <hr />
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
              <div className='col-12'>
                <Collapse id='card-contato' isOpen={collapse === 'LojasTelefone'}>
                  <Card className="border-0">
                    <CardBody id="collapseText">
                      <h4 className='font-Lato font-weight-bold text-blue'>Qual o <span className='blueHighlight'>telefone</span> da <span className='blueHighlight'>loja</span> mais próxima?</h4>
                      <p className='font-size-1'>Nas lojas Dia não temos atendimento via telefone… mas você pode encontrar a loja mais próxima de você<a target='_blank' rel="noreferrer" className='link-red' href='https://www.dia.com.br/lojas/'> aqui.</a></p>
                      <hr />
                      <h4 className='font-Lato font-weight-bold text-blue'>A <span className='blueHighlight'>Loja</span> próxima a minha casa fechou.<br></br> O que faço agora?</h4>
                      <p className='font-size-1'>Poxa, que pena. Mas o DIA tem muitas lojas! Você pode localizar a loja mais próxima basta <a className='link-red' target='_blank' rel="noreferrer" href='https://www.dia.com.br/lojas/'>clicar aqui.</a></p>
                      <hr />
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
              <div className='col-12'>
                <Collapse id='card-contato' isOpen={collapse === 'ProcessoSeletivo'}>
                  <Card className="border-0">
                    <CardBody id="collapseText">
                      <h4 className='font-Lato font-weight-bold text-blue'>Quero fazer parte do time DIA.<br></br> Como participar dos <span className='blueHighlight'>processos seletivos</span>?</h4>
                      <p className='font-size-1'>Que bom que quer fazer parte da nossa equipe! Você pode se inscrever para as vagas disponíveis<a className='link-red' target='_blank' rel="noreferrer" href='https://www.dia.com.br/carreiras/'> clicando aqui</a>.</p>
                      <hr />
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
              {/* <div className='col-12'>
                <Collapse id='card-contato' isOpen={collapse === 'FranquiaImoveis'}>
                  <Card className="border-0">
                    <CardBody id="collapseText">
                      <h4 className='font-Lato font-weight-bold text-blue'>Quero ser um <span className='blueHighlight'>franqueado</span>, o que fazer?</h4>
                      <p className='font-size-1'>Para ser um franqueado Dia é só acessar a aba “<a href='https://www.franquiadia.com.br/rede-dia/' target='_blank' className='link-red' rel="noreferrer">Franquias</a>” aqui nesse site mesmo e se inscrever. Nosso time vai entrar em contato com você.</p>
                      <hr />
                      <h4 className='font-Lato font-weight-bold text-blue'>Tenho um <span className='blueHighlight'>imóvel</span> e gostaria de alugar ou vender para o DIA.<br></br> Com quem posso falar?</h4>
                      <p className='font-size-1'>Temos uma aba aqui no site chamado “<a href='https://www.dia.com.br/imoveis/' target='_blank' className='link-red' rel="noreferrer"> Imóveis</a>” feito especialmente para isso! Por ela, você pode nos passar todas as informações sobre o espaço disponível.</p>
                      <hr />
                    </CardBody>
                  </Card>
                </Collapse>
              </div> */}
              <div className='col-12'>
                <Collapse id='card-contato' isOpen={collapse === 'Pagamento'}>
                  <Card className="border-0">
                    <CardBody id="collapseText">
                      <h4 className='font-Lato font-weight-bold text-blue'>Posso <span className='blueHighlight'>parcelar</span> minhas compras?</h4>
                      <p className='font-size-1'>Claro! Todas as compras acima de R$ 90,00 podem ser parceladas nas lojas Dia. Consulte na loja quais são as bandeiras aceitas e a quantidade de parcelas para cada uma delas.</p>
                      <hr />
                      <h4 className='font-Lato font-weight-bold text-blue'>Tive um problema com o <span className='blueHighlight'>pagamento</span> na loja, o que fazer?</h4>
                      <p className='font-size-1'>Envie-nos as informações aqui no “
                        <ScrollerLink to={'falecomdia'} spy={true} smooth={true} offset={-20} duration={40} className='link-red'>Fale conosco</ScrollerLink>”.
                      </p>
                      <hr />
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
              <div className='col-12'>
                <Collapse id='card-contato' isOpen={collapse === 'TrocasProdutos'}>
                  <Card className="border-0">
                    <CardBody id="collapseText">
                      <h4 className='font-Lato font-weight-bold text-blue'>Preciso <span className='blueHighlight'>trocar</span> um <span className='blueHighlight'>produto</span>. <br></br>Como faço isso?</h4>
                      <p className='font-size-1'>Se o produto que você quer trocar for da <b>Marca Própria do DIA</b> e está com desvio de qualidade nos mande uma foto do produto e nota fiscal <ScrollerLink to={'falecomdia'} spy={true} smooth={true} offset={-20} duration={40} className='link-red'>clicando aqui</ScrollerLink> e aguarde as orientações enviadas por e-mail.</p>
                      <p className='font-size-1'>Se o produto <b>não</b> é da marca própria do DIA, basta retornar à loja DIA em que você fez a compra e apresentar a Nota Fiscal, nossa equipe vai te ajudar com a troca.</p>
                      <hr />
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
              {/* <div className='col-12'>
                <Collapse id='card-contato' isOpen={collapse === 'QueroSerFornecedor'}>
                  <Card className="border-0">
                    <CardBody id="collapseText">
                      <h4 className='font-Lato font-weight-bold text-blue'>Quero ser um <span className='blueHighlight'>fornecedor</span>, por onde começar?</h4>
                      <p className='font-size-1'>É bem simples, basta preencher o formulário do Fale Conosco <ScrollerLink to={'falecomdia'} spy={true} smooth={true} offset={-20} duration={40} className='link-red'>clicando aqui</ScrollerLink>.</p>
                      <hr />
                    </CardBody>
                  </Card>
                </Collapse>
              </div> */}
              {/* <div className='col-12'>
                <Collapse id='card-contato' isOpen={collapse === 'Delivery'}>
                  <Card className="border-0">
                    <CardBody id="collapseText">
                      <h4 className='font-Lato font-weight-bold text-blue'>Como funciona a <span className='blueHighlight'>entrega a domicílio</span>?</h4>
                      <p className='font-size-1'>É bem simples! Faça suas compras pelo nosso WHATSAPP no número <a href='https://api.whatsapp.com/send?phone=5511994296470' rel="noopener noreferrer" target="_blank" className='link-red'>11-994296470</a> ou pelos nossos parceiros RAPPI, IFOOD ou CONNERSHOP. Suas compras chegarão rapidinho no conforto da sua casa. <a href=' https://www.dia.com.br/dia-delivery/' target='_blank' rel="noreferrer" className='link-red'>clique aqui</a> e conheça as opções.</p>
                      <hr />
                    </CardBody>
                  </Card>
                </Collapse>
              </div> */}
              <div className='col-12'>
                <Collapse id='card-contato' isOpen={collapse === 'ClubDia'}>
                  <Card className="border-0">
                    <CardBody id="collapseText">
                      <h4 className='font-Lato font-weight-bold text-blue'>Estou com problemas com o meu <span className='blueHighlight'>ClubDIA</span></h4>
                      <p className='font-size-1'>Temos uma página EXCLUSIVA sobre dúvidas do nosso APP, <a href='https://www.dia.com.br/clubdia/' target='_blank' rel="noreferrer" className='link-red'>clique aqui</a>.</p>
                      <hr />
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const SectionDuvidasMobile = ({ idScroller, setCollapse, collapse, handleCollapse }) => {
  return (
    <>
      <CentralAjuda setCollapse={setCollapse} idScroller={idScroller} handleCollapse={handleCollapse} />
      <div className='container pb-5' id='PerguntasFrequentesMobile'>
        <h1 tabIndex={0} className='text-red mt-5'>Perguntas Frequentes</h1>
        <div className='row'>
          <div className='col-12'>
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div id={`${collapse === 'Ofertas' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => collapse ? handleCollapse(null) : handleCollapse('Ofertas')} className="pt-3 col-lg-6 col-12 d-flex  faqStyle font-size-1">
                    <img style={{ width: 27, height: 27 }} src={Ofertas} alt="icone selecionado" />
                    <span className='ml-3 text-blue font-Lato'>Ofertas</span>
                    <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                  </div>
                  <Collapse id='card-contato' isOpen={collapse === 'Ofertas'} className="col-lg-6 col-12">
                    <Card className="border-0">
                      <CardBody id="collapseTextMobile">
                        <h4 className='font-Lato font-weight-bold text-blue'>Meu <span className='blueHighlight'>produto</span> predileto está em promoção.<br></br> Será que está disponível na loja mais próxima?</h4>
                        <p className='font-size-1'>Poxa, não temos acesso ao estoque individuais das lojas para te responder essa. Mas você pode ir até a loja mais próxima para ver! <a className='link-red' target='_blank' rel="noreferrer" href='https://www.dia.com.br/lojas/'>Clique aqui</a> e consulte qual é. </p>
                        <hr />
                        <h4 className='font-Lato font-weight-bold text-blue'>Quero saber o preço de um <span className='blueHighlight'>produto</span>.<br></br> Como eu descubro?</h4>
                        <p className='font-size-1'>Para saber o preço de algum produto é só ir até uma <a className='link-red' href='https://www.dia.com.br/lojas/' target='_blank' rel="noreferrer" > loja DIA</a> mais próxima! Aproveite também as nossas promoções.</p>
                        <hr />
                        <h4 className='font-Lato font-weight-bold text-blue'>Como faço para saber quais são os <span className='blueHighlight'>produtos</span> em <span className='blueHighlight'>oferta</span>?</h4>
                        <p className='font-size-1'>Tem dois jeitos bem simples de saber isso: Você pode nos mandar uma mensagem no Whatsapp <a href='https://api.whatsapp.com/send?phone=5511953118685' className='link-red'>11-95311-8685</a>  ou, se preferir,  consultar aqui mesmo, no nosso site na aba “<a className='link-red' href='https://www.dia.com.br/lojas/' target='_blank' rel="noreferrer" >Ofertas das semanas</a>”.</p>
                        <hr />
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div id={`${collapse === 'LojasTelefone' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => collapse ? handleCollapse(null) : handleCollapse('LojasTelefone')} className="pt-3 col-lg-6 col-12 d-flex  faqStyle font-size-1">
                    <img style={{ width: 27, height: 27 }} src={LojasTelefone} alt="icone selecionado" />
                    <span className='ml-3 text-blue font-Lato'>Lojas e Telefone</span>
                    <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                  </div>
                  <Collapse id='card-contato' isOpen={collapse === 'LojasTelefone'} className="col-lg-6 col-12">
                    <Card className="border-0">
                      <CardBody id="collapseTextMobile">
                        <h4 className='font-Lato font-weight-bold text-blue'>Qual o <span className='blueHighlight'>telefone</span> da <span className='blueHighlight'>loja</span> mais próxima?</h4>
                        <p className='font-size-1'>Nas lojas Dia não temos atendimento via telefone… mas você pode encontrar a loja mais próxima de você<a className='link-red' href='https://www.dia.com.br/lojas/' target='_blank' rel="noreferrer" > aqui</a>.</p>
                        <hr />
                        <h4 className='font-Lato font-weight-bold text-blue'>A <span className='blueHighlight'>Loja</span> próxima a minha casa fechou.<br></br> O que faço agora?</h4>
                        <p className='font-size-1'>Poxa, que pena. Mas o DIA tem muitas lojas! Você pode localizar a loja mais próxima basta <a className='link-red' href='https://www.dia.com.br/lojas/' target='_blank' rel="noreferrer" >clicar aqui</a>.</p>
                        <hr />
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div id={`${collapse === 'ProcessoSeletivo' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => collapse ? handleCollapse(null) : handleCollapse('ProcessoSeletivo')} className="pt-3 col-lg-6 col-12 d-flex  faqStyle font-size-1">
                    <img style={{ width: 27, height: 27 }} src={ProcessoSeletivo} alt="icone selecionado" />
                    <span className='ml-3 text-blue font-Lato'>Processo Seletivo</span>
                    <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                  </div>
                  <Collapse id='card-contato' isOpen={collapse === 'ProcessoSeletivo'} className="col-lg-6 col-12">
                    <Card className="border-0">
                      <CardBody id="collapseTextMobile">
                        <h4 className='font-Lato font-weight-bold text-blue'>Quero fazer parte do time DIA.<br></br> Como participar dos <span className='blueHighlight'>processos seletivos</span>?</h4>
                        <p className='font-size-1'>Que bom que quer fazer parte da nossa equipe! Você pode se inscrever para as vagas disponíveis<a className='link-red' href='https://www.dia.com.br/carreiras/' target='_blank' rel="noreferrer" > clicando aqui</a>.</p>
                        <hr />
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              </div>
            </div>
            {/* <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div id={`${collapse === 'FranquiaImoveis' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => collapse ? handleCollapse(null) : handleCollapse('FranquiaImoveis')} className="pt-3 col-lg-6 col-12 d-flex  faqStyle font-size-1">
                    <img style={{ width: 27, height: 27 }} src={FranquiaImoveis} alt="icone selecionado" />
                    <span className='ml-3 text-blue font-Lato'>Quero ser um Franqueado</span>
                    <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                  </div>
                  <Collapse id='card-contato' isOpen={collapse === 'FranquiaImoveis'} className="col-lg-6 col-12">
                    <Card className="border-0">
                      <CardBody id="collapseTextMobile">
                        <h4 className='font-Lato font-weight-bold text-blue'>Quero ser um <span className='blueHighlight'>franqueado</span>, o que fazer?</h4>
                        <p className='font-size-1'>Para ser um franqueado Dia é só acessar a aba “<a href='https://www.franquiadia.com.br/rede-dia/' target='_blank' rel="noreferrer" className='link-red'>Franquias</a>” aqui nesse site mesmo e se inscrever. Nosso time vai entrar em contato com você.</p>
                        <hr />
                        <h4 className='font-Lato font-weight-bold text-blue'>Tenho um <span className='blueHighlight'>imóvel</span> e gostaria de alugar ou vender para o DIA.<br></br> Com quem posso falar?</h4>
                        <p className='font-size-1'>Temos uma aba aqui no site chamado “<a href='https://www.dia.com.br/imoveis/' target='_blank' className='link-red' rel="noreferrer"> Imóveis</a>” feito especialmente para isso! Por ela, você pode nos passar todas as informações sobre o espaço disponível.</p>
                        <hr />
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              </div>
            </div> */}
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div id={`${collapse === 'Pagamento' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => collapse ? handleCollapse(null) : handleCollapse('Pagamento')} className="pt-3 col-lg-6 col-12 d-flex  faqStyle font-size-1">
                    <img style={{ width: 27, height: 27 }} src={Pagamento} alt="icone selecionado" />
                    <span className='ml-3 text-blue font-Lato'>Pagamento</span>
                    <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                  </div>
                  <Collapse id='card-contato' isOpen={collapse === 'Pagamento'} className="col-lg-6 col-12">
                    <Card className="border-0">
                      <CardBody id="collapseTextMobile">
                        <h4 className='font-Lato font-weight-bold text-blue'>Posso <span className='blueHighlight'>parcelar</span> minhas compras?</h4>
                        <p className='font-size-1'>Claro! Todas as compras acima de R$ 90,00 podem ser parceladas nas lojas Dia. Consulte na loja quais são as bandeiras aceitas e a quantidade de parcelas para cada uma delas.</p>
                        <hr />
                        <h4 className='font-Lato font-weight-bold text-blue'>Tive um problema com o <span className='blueHighlight'>pagamento</span> na loja, o que fazer?</h4>
                        <p className='font-size-1'>Envie-nos as informações aqui no “
                          <ScrollerLink to={'falecomdia'} spy={true} smooth={true} offset={-20} duration={40} className='link-red'> Fale conosco</ScrollerLink>”.
                        </p>
                        <hr />
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div id={`${collapse === 'TrocasProdutos' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => collapse ? handleCollapse(null) : handleCollapse('TrocasProdutos')} className="pt-3 col-lg-6 col-12 d-flex  faqStyle font-size-1">
                    <img style={{ width: 27, height: 27 }} src={TrocasProdutos} alt="icone selecionado" />
                    <span className='ml-3 text-blue font-Lato'>Trocas de Produtos</span>
                    <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                  </div>
                  <Collapse id='card-contato' isOpen={collapse === 'TrocasProdutos'} className="col-lg-6 col-12">
                    <Card className="border-0">
                      <CardBody id="collapseTextMobile">
                        <h4 className='font-Lato font-weight-bold text-blue'>Preciso <span className='blueHighlight'>trocar</span> um <span className='blueHighlight'>produto</span>. <br></br>Como faço isso?</h4>
                        <p className='font-size-1'>Se o produto que você quer trocar for da marca DIA e está com desvio de qualidade nos mande uma foto do produto e nota fiscal <ScrollerLink to={'falecomdia'} spy={true} smooth={true} offset={-20} duration={40} className='link-red'>clicando aqui</ScrollerLink> e se dirija até a loja mais próxima com a Nota Fiscal, bem simples. </p>
                        <p className='font-size-1'>Se o seu produto não é marca própria, basta retornar à loja DIA em que você fez a compra e apresentar a Nota fiscal, nossa equipe vai te ajudar com a troca.</p>
                        <hr />
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              </div>
            </div>
            {/* <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div id={`${collapse === 'QueroSerFornecedor' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => collapse ? handleCollapse(null) : handleCollapse('QueroSerFornecedor')} className="pt-3 col-lg-6 col-12 d-flex  faqStyle font-size-1">
                    <img style={{ width: 27, height: 27 }} src={QueroSerFornecedor} alt="icone selecionado" />
                    <span className='ml-3 text-blue font-Lato'>Quero Ser um Fornecedor</span>
                    <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                  </div>
                  <Collapse id='card-contato' isOpen={collapse === 'QueroSerFornecedor'} className="col-lg-6 col-12">
                    <Card className="border-0">
                      <CardBody id="collapseTextMobile">
                        <h4 className='font-Lato font-weight-bold text-blue'>Quero ser um <span className='blueHighlight'>fornecedor</span>, por onde começar?</h4>
                        <p className='font-size-1'>É bem simples, basta preencher o formulário do Fale Conosco <ScrollerLink to={'falecomdia'} spy={true} smooth={true} offset={-20} duration={40} className='link-red'>clicando aqui</ScrollerLink>.</p>
                        <hr />
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              </div>
            </div> */}
            {/* <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div id={`${collapse === 'Delivery' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => collapse ? handleCollapse(null) : handleCollapse('Delivery')} className="pt-3 col-lg-6 col-12 d-flex  faqStyle font-size-1">
                    <img style={{ width: 27, height: 27 }} src={Delivery} alt="icone selecionado" />
                    <span className='ml-3 text-blue font-Lato'>Delivery</span>
                    <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                  </div>
                  <Collapse id='card-contato' isOpen={collapse === 'Delivery'} className="col-lg-6 col-12">
                    <Card className="border-0">
                      <CardBody id="collapseTextMobile">
                        <h4 className='font-Lato font-weight-bold text-blue'>Como funciona a <span className='blueHighlight'>entrega a domicílio</span>?</h4>
                        <p className='font-size-1'>É bem simples! Faça suas compras pelo nosso APP DIA EXPRESS ou pelos nossos parceiros RAPPI, IFOOD ou CONNERSHOP. Suas compras chegarão rapidinho no conforto da sua casa. <a href=' https://www.dia.com.br/dia-delivery/' className='link-red'>clique aqui</a> e conheça as opções. </p>
                        <hr />
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              </div>
            </div> */}
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div id={`${collapse === 'ClubDia' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => collapse ? handleCollapse(null) : handleCollapse('ClubDia')} className="pt-3 col-lg-6 col-12 d-flex  faqStyle font-size-1">
                    <img style={{ width: 27, height: 27 }} src={ClubDia} alt="icone selecionado" />
                    <span className='ml-3 text-blue font-Lato'>ClubDia</span>
                    <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
                  </div>
                  <Collapse id='card-contato' isOpen={collapse === 'ClubDia'} className="col-lg-6 col-12">
                    <Card className="border-0">
                      <CardBody id="collapseTextMobile">
                        <h4 className='font-Lato font-weight-bold text-blue'>Estou com problemas com o meu <span className='blueHighlight'>ClubDIA</span></h4>
                        <p className='font-size-1'>Temos uma página EXCLUSIVA sobre dúvidas do nosso APP, <a href='https://www.dia.com.br/clubdia/' target='_blank' rel="noreferrer" className='link-red'>clique aqui</a>.</p>
                        <hr />
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              </div>
            </div>
            <div className='row'>
              <div id={`${collapse === 'AchadosPerdidos' ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => collapse ? handleCollapse(null) : handleCollapse('AchadosPerdidos')} className="mt-lg-3 pt-lg-3 col-lg-6 col-12 d-flex  faqStyleLast font-size-1">
                <img style={{ width: 27, height: 27 }} src={estrela} alt="icone selecionado" />
                <span className='ml-3 text-blue font-Lato'>Achados e Perdidos</span>
                <img style={{ width: 27, height: 27 }} className="ml-auto" src={RedArrow} alt="icone selecionado" />
              </div>
              <Collapse id='card-contato' isOpen={collapse === 'AchadosPerdidos'} className="col-lg-6 col-12">
                <Card className="border-0">
                  <CardBody id="collapseTextMobile">
                    <h4 className='font-Lato font-weight-bold text-blue'>Esqueci um <span className='blueHighlight'>item pessoal</span> na loja.<br></br> E agora?</h4>
                    <p className='font-size-1'>Não se preocupe. Se você esqueceu algo, venha até a loja e veja com a nossa equipe se o seu pertence foi encontrado e deixado com algum dos nossos colaboradores.</p>
                    <hr />
                  </CardBody>
                </Card>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const sendEvent = (action, label = 'none') => {
  // eslint-disable-next-line no-undef
  if (typeof window !== 'undefined') {
    if (!window.dataLayer) {
      window.dataLayer = []
    }
    window.dataLayer.push({
      event: 'contato',
      eventAction: action,
      eventLabel: label
    })
  }
}

export default function Contato (props) {
  const { data } = props
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const [collapse, setCollapse] = useState('Ofertas')

  function handleCollapse (value) {
    setCollapse(value)
    let tagManagerArgs = {
      dataLayer: {
        event: 'BotaoContato',
        eventAction: 'clicar-botao-contato',
        eventLabel: value
      }
    }

    console.log(tagManagerArgs)
    TagManager.dataLayer(tagManagerArgs)
  }

  const [assuntoContato, setAssuntoContato] = useState([])
  const [disableSubmitButton, setDisableSubmitButton] = useState(true)

  function selecionaAssunto (event) {
    const filtroAssunto = assuntoContato.find((assunto) => assunto.name === event.target.value)
    if (filtroAssunto && filtroAssunto.open) {
      setCollapse(filtroAssunto.open)
      if (window.innerWidth < 992) {
        scroller.scrollTo('PerguntasFrequentesMobile')
      } else {
        scroller.scrollTo('PerguntasFrequentes')
      }
    }
    setDisableSubmitButton(filtroAssunto.disableButton)
  }

  const currentStore = typeof window !== 'undefined' ? window.localStorage.getItem('currentMarket') : ''
  const currentMarketJson = currentStore ? JSON.parse(currentStore) : ''
  const [currentMarket, setCurrentMarket] = useState(currentMarketJson)
  const modalRef = useRef()
  const breadC = {
    visible: true,
    color: 'black',
    items: [
      { path: '/', name: 'Home' },
      { path: '/contato/', name: 'Contato' }
    ]
  }

  const [count, setCount] = useState(true)
  const motivoArray = {
    Reclamação:
      [
        { id: 'atendimento', name: 'Atendimento' },
        { id: 'outros', name: 'Outros' },
        { id: 'pagamento', name: 'Pagamento' },
        { id: 'trocaproduto', name: 'Troca de produto Marca DIA' },
        { id: 'trocaproduto', name: 'Troca de produto outras marcas', open: 'TrocasProdutos' },
        { id: 'clubdia', name: 'ClubDia' }
      ],
    Informação:
      [
        {
          id: 'parcelarcompras',
          name: 'Como parcelar as compras',
          open: 'Pagamento',
          disableButton: true,
          anexo: {
            exibir: true,
            texto: 'teste'
          },
          inputs: {
            data: true,
            unidadeDia: true,
            valorCompra: true,
            digitosCartao: true,
            endereco: true
          }
        },
        // { id: 'entregadomicilio', name: 'Entrega a domicilio', open: 'Delivery', disableButton: true },
        { id: 'itenspromocao', name: 'Itens em promoção', open: 'Ofertas', disableButton: true },
        { id: 'lojafechada', name: 'Loja Fechada', open: 'LojasTelefone', disableButton: true },
        { id: 'outros', name: 'Outros' },
        { id: 'precoproduto', name: 'Preço de produto', open: 'Ofertas', disableButton: true },
        { id: 'processoseletivo', name: 'Processo seletivo', open: 'ProcessoSeletivo', disableButton: true },
        { id: 'produtoesquecido', name: 'Produtos esquecidos nas lojas', open: 'AchadosPerdidos', disableButton: true },
        { id: 'serfornecedor', name: 'Quero ser um fornecedor' },
        // { id: 'serfranquiado', name: 'Quero ser um Franqueado', open: 'FranquiaImoveis', disableButton: true },
        { id: 'telefonelojaproxima', name: 'Telefone da loja mais próxima', open: 'LojasTelefone', disableButton: true },
        { id: 'trocaproduto', name: 'Troca de produto Marca DIA', open: 'TrocasProdutos', disableButton: true },
        // { id: 'vendalocacao', name: 'Venda/locação de imóvel', open: 'FranquiaImoveis', disableButton: true }
      ],
    Sugestão:
      [{ id: 'loja', name: 'Loja' }],
    Elogio:
      [{ id: 'loja', name: 'Loja' }, { id: 'atendimento', name: 'Atendimento' }]
  }

  const hiddenFields = () => {
    document.getElementById('datacompra').parentElement.classList.remove('d-block')
    document.getElementById('datacompra').parentElement.classList.add('d-none')
    document.getElementById('datafabricacao').parentElement.classList.remove('d-block')
    document.getElementById('datafabricacao').parentElement.classList.add('d-none')
    document.getElementById('loteproduto').parentElement.classList.remove('d-block')
    document.getElementById('loteproduto').parentElement.classList.add('d-none')
    document.getElementById('nomeproduto').parentElement.classList.remove('d-block')
    document.getElementById('nomeproduto').parentElement.classList.add('d-none')
    document.getElementById('desvioqualidade').parentElement.classList.remove('d-block')
    document.getElementById('desvioqualidade').parentElement.classList.add('d-none')
    document.getElementById('arquivo').parentElement.classList.remove('d-block')
    document.getElementById('arquivo').parentElement.classList.add('d-none')
    document.getElementById('valorcompra').parentElement.classList.remove('d-block')
    document.getElementById('valorcompra').parentElement.classList.add('d-none')
    document.getElementById('4digitoscartao').parentElement.classList.remove('d-block')
    document.getElementById('4digitoscartao').parentElement.classList.add('d-none')
  }

  const getWatch = (data) => {
    if (count) {
      hiddenFields()
      setCount(false)
    }

    if (data.motivodocontato === 'Elogio' || data.motivodocontato === 'Sugestão') {
      document.getElementById('datacompra').parentElement.classList.remove('d-block')
      document.getElementById('datacompra').parentElement.classList.add('d-none')
      document.getElementById('datafabricacao').parentElement.classList.remove('d-block')
      document.getElementById('datafabricacao').parentElement.classList.add('d-none')
      document.getElementById('loteproduto').parentElement.classList.remove('d-block')
      document.getElementById('loteproduto').parentElement.classList.add('d-none')
      document.getElementById('nomeproduto').parentElement.classList.remove('d-block')
      document.getElementById('nomeproduto').parentElement.classList.add('d-none')
      document.getElementById('desvioqualidade').parentElement.classList.remove('d-block')
      document.getElementById('desvioqualidade').parentElement.classList.add('d-none')
      document.getElementById('valorcompra').parentElement.classList.remove('d-block')
      document.getElementById('valorcompra').parentElement.classList.add('d-none')
      document.getElementById('4digitoscartao').parentElement.classList.remove('d-block')
      document.getElementById('4digitoscartao').parentElement.classList.add('d-none')
      document.getElementById('arquivo').parentElement.classList.remove('d-block')
      document.getElementById('arquivo').parentElement.classList.add('d-none')
      document.getElementById('mensagem').parentElement.classList.remove('d-none')
      document.getElementById('mensagem').parentElement.classList.add('d-block')
      document.getElementsByClassName('btnSubmitStyle')[0].classList.remove('d-none')
      document.getElementsByClassName('btnSubmitStyle')[0].classList.add('d-block')
    }

    if (data.motivodocontato === 'Reclamação') {
      if (data.assunto === 'Troca de produto Marca DIA') {
        document.getElementById('datafabricacao').parentElement.classList.remove('d-none')
        document.getElementById('datafabricacao').parentElement.classList.add('d-block')
        document.getElementById('loteproduto').parentElement.classList.remove('d-none')
        document.getElementById('loteproduto').parentElement.classList.add('d-block')
        document.getElementById('nomeproduto').parentElement.classList.remove('d-none')
        document.getElementById('nomeproduto').parentElement.classList.add('d-block')
        document.getElementById('desvioqualidade').parentElement.classList.remove('d-none')
        document.getElementById('desvioqualidade').parentElement.classList.add('d-block')
      } else {
        document.getElementById('datafabricacao').parentElement.classList.remove('d-block')
        document.getElementById('datafabricacao').parentElement.classList.add('d-none')
        document.getElementById('loteproduto').parentElement.classList.remove('d-block')
        document.getElementById('loteproduto').parentElement.classList.add('d-none')
        document.getElementById('nomeproduto').parentElement.classList.remove('d-block')
        document.getElementById('nomeproduto').parentElement.classList.add('d-none')
        document.getElementById('desvioqualidade').parentElement.classList.remove('d-block')
        document.getElementById('desvioqualidade').parentElement.classList.add('d-none')
      }

      // if (data.assunto === 'Troca de produto outras marcas') {
      //   console.log('bateu')
      //   document.getElementById('mensagem').parentElement.classList.remove('d-block')
      //   document.getElementById('mensagem').parentElement.classList.add('d-none')
      // } else {
      //   console.log('puts')
      //   document.getElementById('mensagem').parentElement.classList.remove('d-none')
      //   document.getElementById('mensagem').parentElement.classList.add('d-block')
      // }

      if (data.assunto === 'ClubDia' || data.assunto === 'Troca de produto Marca DIA' || data.assunto === 'Atendimento' || data.assunto === 'Loja' || data.assunto === 'Pagamento' || data.assunto === 'Outros') {
        document.getElementById('arquivo').parentElement.classList.remove('d-none')
        document.getElementById('arquivo').parentElement.classList.add('d-block')
      } else {
        document.getElementById('arquivo').parentElement.classList.remove('d-block')
        document.getElementById('arquivo').parentElement.classList.add('d-none')
      }

      if (data.assunto === 'Pagamento' || data.assunto === 'Troca de produto Marca DIA') {
        document.getElementById('datacompra').parentElement.classList.remove('d-none')
        document.getElementById('datacompra').parentElement.classList.add('d-block')
      } else {
        document.getElementById('datacompra').parentElement.classList.remove('d-block')
        document.getElementById('datacompra').parentElement.classList.add('d-none')
      }

      if (data.assunto === 'Pagamento') {
        document.getElementById('valorcompra').parentElement.classList.remove('d-none')
        document.getElementById('valorcompra').parentElement.classList.add('d-block')
        document.getElementById('4digitoscartao').parentElement.classList.remove('d-none')
        document.getElementById('4digitoscartao').parentElement.classList.add('d-block')
      } else {
        document.getElementById('valorcompra').parentElement.classList.remove('d-block')
        document.getElementById('valorcompra').parentElement.classList.add('d-none')
        document.getElementById('4digitoscartao').parentElement.classList.remove('d-block')
        document.getElementById('4digitoscartao').parentElement.classList.add('d-none')
      }

      if (data.assunto === 'Troca de produto Marca DIA') {
        if (data.desvioqualidade === 'Corpo Estranho') {
          sethandleMessageCorpoestranho(true)
          document.getElementById('mensagem').parentElement.classList.remove('d-block')
          document.getElementById('mensagem').parentElement.classList.add('d-none')
          document.getElementById('arquivo').parentElement.classList.remove('d-block')
          document.getElementById('arquivo').parentElement.classList.add('d-none')
          document.getElementsByClassName('btnSubmitStyle')[0].classList.remove('d-block')
          document.getElementsByClassName('btnSubmitStyle')[0].classList.add('d-none')
        } else {
          sethandleMessageSubmit(true)
          sethandleMessageCorpoestranho(false)
          document.getElementById('mensagem').parentElement.classList.remove('d-none')
          document.getElementById('mensagem').parentElement.classList.add('d-block')
          document.getElementById('arquivo').parentElement.classList.remove('d-none')
          document.getElementById('arquivo').parentElement.classList.add('d-block')
          document.getElementsByClassName('btnSubmitStyle')[0].classList.remove('d-none')
          document.getElementsByClassName('btnSubmitStyle')[0].classList.add('d-block')
        }
      } else if (data.assunto === 'Troca de produto outras marcas') {
        document.getElementById('mensagem').parentElement.classList.remove('d-block')
        document.getElementById('mensagem').parentElement.classList.add('d-none')
        document.getElementsByClassName('btnSubmitStyle')[0].classList.remove('d-block')
        document.getElementsByClassName('btnSubmitStyle')[0].classList.add('d-none')
      } else {
        document.getElementById('mensagem').parentElement.classList.remove('d-none')
        document.getElementById('mensagem').parentElement.classList.add('d-block')
        document.getElementsByClassName('btnSubmitStyle')[0].classList.remove('d-none')
        document.getElementsByClassName('btnSubmitStyle')[0].classList.add('d-block')
      }
    }

    if (data.motivodocontato === 'Informação') {
      if (data.assunto === 'Outros' || data.assunto === 'Quero ser um fornecedor') {
        document.getElementById('arquivo').parentElement.classList.remove('d-none')
        document.getElementById('arquivo').parentElement.classList.add('d-block')
        document.getElementById('mensagem').parentElement.classList.remove('d-none')
        document.getElementById('mensagem').parentElement.classList.add('d-block')
        document.getElementsByClassName('btnSubmitStyle')[0].classList.remove('d-none')
        document.getElementsByClassName('btnSubmitStyle')[0].classList.add('d-block')
      } else {
        document.getElementById('4digitoscartao').parentElement.classList.remove('d-block')
        document.getElementById('4digitoscartao').parentElement.classList.add('d-none')
        document.getElementById('valorcompra').parentElement.classList.remove('d-block')
        document.getElementById('valorcompra').parentElement.classList.add('d-none')
        document.getElementById('datacompra').parentElement.classList.remove('d-block')
        document.getElementById('datacompra').parentElement.classList.add('d-none')
        document.getElementById('arquivo').parentElement.classList.remove('d-block')
        document.getElementById('arquivo').parentElement.classList.add('d-none')
        document.getElementById('mensagem').parentElement.classList.remove('d-block')
        document.getElementById('mensagem').parentElement.classList.add('d-none')
        document.getElementsByClassName('btnSubmitStyle')[0].classList.remove('d-block')
        document.getElementsByClassName('btnSubmitStyle')[0].classList.add('d-none')
      }
    }
  }

  const [assuntoArray, setAssuntoArray] = useState([])

  const fieldMotivoDoContato = (field, register, errors) => {
    const motivo = ['Elogio', 'Informação', 'Reclamação']
    const assunto = {
      Elogio: ['Loja', 'Atendimento'],
      Informação: ['Como parcelar as compras', 'Entrega a domicilio', 'Itens em promoção', 'Loja Fechada', 'Preço de produto', 'Processo seletivo', 'Produtos esquecidos nas lojas', 'Quero ser um Franqueado', 'Telefone da loja mais próxima', 'Troca de produto Marca DIA', 'Venda/locação de imóvel'],
      Reclamação: ['Atendimento', 'ClubDia', 'Pagamento', 'Troca de produto Marca DIA', 'Troca de produto outras marcas'],
      Sugestão: ['Loja']
    }
    const handleMotivoSelected = (event) => {
      setAssuntoArray(assunto[event.target.value])
    }

    return (
      <>
        <label htmlFor={field.name}>{field.label}</label>
        <select
          id={field.name}
          name={field.name}
          {...register(field.name, {
            onChange: (e) => { handleMotivoSelected(e); setAssuntoContato(motivoArray[e.target.value]) },
            required: field.required
          })}
          className={`${errors[field.name] ? 'error' : ''}`}
          defaultValue={''}
        >
          <option value='' disabled> Selecione o Motivo</option>
          {motivo.map((item, index) => {
            return (
              <option key={`${field.name}-option-${index}`}>{item}</option>
            )
          })}
        </select>
      </>
    )
  }

  const fieldAssunto = (field, register, errors) => {
    return (
      <>
        <label htmlFor={field.name}>{field.label}</label>
        <select
          id={field.name}
          name={field.name}
          {...register(field.name, {
            onChange: (e) => { selecionaAssunto(e); sethandleMessageSubmit(false) },
            required: field.required
          })}
          className={`${errors[field.name] ? 'error' : ''}`}
          defaultValue={''}
        >
          <option value='' disabled> Selecione o Assunto</option>
          {assuntoArray.map((item, index) => {
            return (
              <option key={`${field.name}-option-${index}`}>{item}</option>
            )
          })}
        </select>
      </>
    )
  }

  const fieldData = (field, register, errors) => {
    return (
      <>
        <label htmlFor={field.name}>{field.label}</label>
        <InputMask
          mask='99/99/9999'
          maskChar={null}
          id={field.name}
          name={field.name}
          type='text'
          placeholder={field.label}
          className={`${errors[field.name] ? 'error' : ''} dataSelect`}
          {...register(field.name, { required: field.required })}
        />
      </>
    )
  }

  const fieldDesvioQualidade = (field, register, errors) => {
    const desvioqualidade = ['Odor', 'Defeitos visuais', 'Embalagem sem lacre', 'Embolorado/mofado', 'Estufamento', 'Larvas/insetos', 'Corpo Estranho']
    return (
      <>
        <label htmlFor={field.name}>{field.label}</label>
        <select
          id={field.name}
          name={field.name}
          {...register(field.name, {
            required: field.required
          })}
          className={`${errors[field.name] ? 'error' : ''}`}
          defaultValue={''}
        >
          <option value='' disabled> Informe o Desvio de Qualidade</option>
          {desvioqualidade.map((item, index) => {
            return (
              <option key={`${field.name}-option-${index}`}>{item}</option>
            )
          })}
        </select>
        <div>
          {handleMessageCorpoestranho &&
            <p className='text-red pb-3 pl-2'>Entre em contato com o nosso SAC 0800 723 1470
            </p>
          }
        </div>
      </>
    )
  }
  // function fieldLocalizarLoja (event) {
  //   console.log('Loja: ' + currentMarket.name)
  // }
  const buscaLojasField = (field, register, errors, setValue) => {
    console.log('Loja: ' + currentMarket.name)
    register(field.name, { value: currentMarket.name })

    const setValueForm = () => {
      setValue(field.name, currentMarket.name)
    }

    return (
      <>
        <div className='font-size-1'>
          <p>Por favor, confirme a loja da ocorrência</p>
          <p>Loja Selecionada:
            <span to='/lojas/' className="font-Lato font-size-1 text-dark" >
              <u id="currentMarket" className="font-weight-bold pl-2">{currentMarket ? currentMarket.name : 'Selecione uma Loja'}</u>
            </span>
          </p>
        </div>
        <BuscarLoja current={currentMarket} setCurrentMarket={setCurrentMarket} setValueForm={setValueForm}
        />
      </>
    )
  }
  const customFields = {
    motivodocontato: fieldMotivoDoContato,
    assunto: fieldAssunto,
    datacompra: fieldData,
    datafabricacao: fieldData,
    desvioqualidade: fieldDesvioQualidade,
    buscaloja: buscaLojasField
    // localizarloja: fieldLocalizarLoja
  }
  const [handleMessageSubmit, sethandleMessageSubmit] = useState(false)
  const [handleMessageCorpoestranho, sethandleMessageCorpoestranho] = useState(false)

  return (
    <Layout footerFaleDia={true} breadCrumb={breadC} homeCallback={setCurrentMarket} ref={modalRef}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Contato - Dia Supermercados</title>
        <meta name="description" content="Entre em contato com o DIA. Acesse nossa Central de Ajuda para esclarecer suas dúvidas ou se precisar preencha o formulário." />
        <meta name="keywords" content="contato dia, fale com o dia, central de ajuda, perguntas frequentes" />
        <meta name="author" content="DIA Supermercados" />
        <script defer type='text/javascript'> 
          {`
            (function() {
              window.NeoAssistTag = {};
              NeoAssistTag.querystring = true;
              NeoAssistTag.pageid = '';
              NeoAssistTag.clientdomain = 'grupodia.neoassist.com'; 
              NeoAssistTag.initialize = {};
              var na = document.createElement('script'); 
              na.type = 'text/javascript'; 
              na.async = true; 
              na.src = 'https://cdn.atendimen.to/n.js'; 
              var s = document.getElementsByTagName('script')[0]; 
              s.parentNode.insertBefore(na, s); 
            })();
          `} 
        </script>
      </Helmet>
      <section>
        <div className="position-absolute w-100" id='TopPage'>
          <GatsbyImage
            image={props.data.imgDesktop.childImageSharp.gatsbyImageData}
            className="position-relative carrinhos img-fuild d-lg-block d-none"
            alt="Carrinhos de Supermercado" />
          <GatsbyImage
            image={props.data.imgMobile.childImageSharp.gatsbyImageData}
            className="position-relative carrinhos img-fuild d-lg-none d-block"
            alt="Carrinhos de Supermercado" />
        </div>
        <section className="position-relative carrinhos container d-flex text-white p-0">
          <h1 tabIndex={0} className="mb-lg-5 ml-lg-0 ml-2 font-size-42px">Fale com o DIA</h1>
        </section>
        <div className="d-block d-lg-none">
          <SectionDuvidasMobile idScroller="PerguntasFrequentesMobile" collapse={collapse} setCollapse={setCollapse} handleCollapse={handleCollapse} />
        </div>
        <div className="d-none d-lg-block">
          <SectionDuvidas idScroller="PerguntasFrequentes" collapse={collapse} setCollapse={setCollapse} handleCollapse={handleCollapse} />
        </div>
        <section className="bg-dia-gray pt-5" id='falecomdia'>
          <div className="container-fluid pt-lg-5">
            <div className="row">
              <div className="col-lg-5 padding-left-custom pr-lg-5 font-size-1">
                <p className="mb-3">O DIA nasceu para deixar a sua vida mais simples e gostosa. Tudo o que fazemos é para criar uma experiência super agradável, desde o ambiente até a qualidade dos produtos que você encontra.</p>
                <p className="mb-3">Por isso mesmo, agradecemos qualquer mensagem – inclusive as críticas -, porque elas nos ajudam a melhorar sempre. Por favor, preencha o formulário que responderemos o mais rápido possível.</p>
                {/* <button className='btn btn-light' onClick={() => selectDesvio()} >teste</button> */}
              </div>
              <div className="col-lg-7 bg-white contatoWhite pr-0 pl-0 pt-lg-0 pt-5 pb-lg-0 pb-5">
                <div className="col-lg-9 h-100 p-lg-4 full">
                  <div id="NeoassistCentral"></div>
                  {/* {data &&
                    <div className="row formstyle">
                      <RenderForm
                        disabledButton={disableSubmitButton}
                        getWatch={getWatch}
                        formData={data.cloudOfertasForm}
                        customFields={customFields}
                        setSuccessSubmit={setSubmitSuccess}
                        formGroups={data.cloudOfertasForm.formGroups}
                        formGroupsStyle='formGroupsStyle font-size-13 mt-4 mb-0'
                        errorLabel='top'
                        containerStyle='container-fluid'
                        rowStyle='row'
                        inputStyle='input-contato font-size-1'
                        inputSearchStyle='custom-file-upload'
                        btnContainerStyle='d-flex align-items-end mt-2 col-lg-12 col-12 w-100'
                        btnStyle='btnSubmitStyle font-size-1 w-100 col-lg-5 col-12 py-2'
                        btnName='Enviar Mensagem'
                        placeholder={true}
                        btnLoaderContainer='loaderStyle'
                        btnLoaderColor='white'
                        btnLoaderLabel='Processando...'
                        btnLoaderSize={24}
                      />
                    </div>
                  }
                  {submitSuccess &&
                    <div className="col-12 p-0">
                      <div className="d-flex align-items-center justify-content-center bg-dia-green rounded p-2 my-3">
                        <p
                          className="border text-white rounded-circle mb-0 mr-3 font-size-15 d-flex justify-content-center align-text-center"
                          style={{ width: '45px', height: '45px' }} >✔</p>
                        <p className="font-weight-bold text-white text-center font-size-1125 m-0">
                          {handleMessageSubmit && <>Se o seu produto está na data de validade, basta ir até uma das nossas lojas realizar a troca!</>
                          }
                          {!handleMessageSubmit && <>Contato enviado com sucesso</>
                          }
                        </p>
                      </div>
                    </div>
                  } */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <ScrollerLink
        to={'TopPage'}
        className='d-md-none'
        style={{ position: 'fixed', bottom: '4px', right: '4px', zIndex: '100' }} rel="noreferrer"
        spy={true}
        smooth={true}
        offset={-65}
        duration={40}>
        <img src={ScrollTop} alt="icone selecionado" />
      </ScrollerLink>
    </Layout>
  )
}

export const query = graphql`{
  imgDesktop: file(relativePath: {eq: "contato/Banner-final.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  imgMobile: file(relativePath: {eq: "contato/Banner-final.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 360, height: 240, layout: CONSTRAINED)
    }
  }
  lojas: allCloudOfertasLoja {
    nodes {
      id
      name
      storeNumber
      city
    }
  }
  cloudOfertasForm(name: {eq: "FALE_COM_DIA"}) {
    id
    name
    formGroups{
      id
      order
      name
    }
    formTypeField {
      name
      type
      required
      style
      length
      label
      mask
      formLov {
        formLovData {
          id
          value
          createdDate
        }
      }
      formGroups {
        id
      }
    }
  }
  allCloudOfertasLoja {
    edges {
      node {
        name
        uf
        city
      }
    }
  }
}
`
